import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Inicio from '../views/Inicio.vue'
import Solicitud from '../views/Solicitud.vue'
import Solicitud2 from '../views/Solicitud2.vue'
import SolicitudCompleta from '../views/SolicitudCompleta.vue'
import SolicitudFederal from '../views/SolicitudFederal.vue'
import SolicitudD from '../views/SolicitudD.vue'
import Solicitudes from '../views/Solicitudes.vue'

import Correspondencia from '../views/Correspondencia.vue'
import Correspondencia0 from '../views/Correspondencia0.vue'
import Correspondencia2 from '../views/Correspondencia2.vue'
import Menu1 from '../views/Menu1.vue'
import Menu2 from '../views/Menu2.vue'
import Menu3 from '../views/Menu3.vue'
import Solicitud3 from '../views/Solicitud3.vue'
import Acuerdos2 from '../views/Acuerdos2.vue'
import Padron2 from '../views/Padron2.vue'

import Estatus from '../views/Estatus.vue'
import Acceso from '../views/Acceso.vue'
import Areas from '../views/Areas.vue'
import Bitacora from '../views/Bitacora.vue'
import PrioridadSolicitud from '../views/PrioridadSolicitud.vue'
import TipoSolicitud from '../views/TipoSolicitud.vue'
import EstatusSolicitud from '../views/EstatusSolicitud.vue'
import Usuarios from '../views/Usuarios.vue'
import RAreas from '../views/RAreas.vue'
import REstatus from '../views/REstatus.vue'
import RTipos from '../views/RTipos.vue'
import GAtencionArea from '../views/GAtencionArea.vue'
import Salir from '../views/Salir.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/solicitud/:id',
    name: 'SolicitudD',
    component: SolicitudD
  },
  {
    path: '/solicitudd',
    name: 'SolicitudD2',
    component: SolicitudD
  },
  {
    path: '/solicitud2',
    name: 'Solicitud2',
    component: Solicitud2,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitud',
    name: 'Solicitud',
    component: Solicitud
  },
  {
    path: '/solicitudcompleta',
    name: 'SolicitudCompleta',
    component: SolicitudCompleta,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitudfederal',
    name: 'SolicitudFederal',
    component: SolicitudFederal,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/estatus',
    name: 'Estatus',
    component: Estatus
  },
  {
    path: '/acceso',
    name: 'Acceso',
    component: Acceso
  },
  {
    path: '/padron2',
    name: 'Padron2',
    component: Padron2,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/menu1',
    name: 'Menu1',
    component: Menu1,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/menu2',
    name: 'Menu2',
    component: Menu2,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/menu3',
    name: 'Menu3',
    component: Menu3,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitud3',
    name: 'Solicitud3',
    component: Solicitud3,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/acuerdos2',
    name: 'Acuerdos2',
    component: Acuerdos2,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/correspondencia0',
    name: 'Correspondencia0',
    component: Correspondencia0,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/correspondencia2',
    name: 'Correspondencia2',
    component: Correspondencia2,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/correspondencia',
    name: 'Correspondencia',
    component: Correspondencia,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    component: Solicitudes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/solicitudes/:id',
    name: 'SolicitudesId',
    component: Solicitudes,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        sessionStorage.setItem('redirectPath', to.path);
        next('/acceso')
      }
    }
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/tiposolicitud',
    name: 'TipoSolicitud',
    component: TipoSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/prioridadsolicitud',
    name: 'PrioridadSolicitud',
    component: PrioridadSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/estatusolicitud',
    name: 'EstatusSolicitud',
    component: EstatusSolicitud,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: Usuarios,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/rareas',
    name: 'RAreas',
    component: RAreas,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/rtipos',
    name: 'RTipos',
    component: RTipos,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
  {
    path: '/restatus',
    name: 'REstatus',
    component: REstatus,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    },
  },
  {
    path: '/gatencionarea',
    name: 'GAtencionArea',
    component: GAtencionArea,
    beforeEnter(to, from, next) {
      if (store.getters.esAdministrador) {
        next()
      } else {
        next('/acceso')
      }
    }

  },
  {
    path: '/salir',
    name: 'Salir',
    component: Salir,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }

  },
  {
    path: '/bitacora',
    name: 'Bitacora',
    component: Bitacora,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
