<template>
  <div class="hello">
    <h4>Ventanilla Única</h4>
    <p></p>
    <b-carousel
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;">
      <b-carousel-slide img-src="../assets/1tlaxcala_07.png">
        <img slot="img" class="d-block img-fluid w-100" src="../assets/1tlaxcala_06.png" />
      </b-carousel-slide>
      <b-carousel-slide img-src="../assets/1tlaxcala_07.png">
        <img slot="img" class="d-block img-fluid w-100" src="../assets/1tlaxcala_07.png" />
      </b-carousel-slide>
      <b-carousel-slide img-src="../assets/1tlaxcala_07.png">
        <img slot="img" class="d-block img-fluid w-100" src="../assets/1Tlaxcala_02.png" />
      </b-carousel-slide>
    </b-carousel>
    
  </div>
</template>

<script>
export default {
  name: "Inicio",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
