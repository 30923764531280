<template>
  <div>
    <h3 class="mt-3 titulo"><strong>Agregar correspondencia</strong></h3>
    <hr />

        <div class="row">
          <label class="col-3">
            Correspondencia <span class="requerido">*</span></label
          >
          <div class="col-9">
            <select
              v-model="solicitud.status"
              :class="estatus($v.solicitud.status)"
              class="form-control"
            >
              <option
                v-for="elemento in listaGrupos"
                :key="elemento.id"
                v-bind:value="elemento.id"
              >
                {{ elemento.valor }}
              </option>
            </select>
          </div>
        </div>
        {{solicitud}}
        <div v-if="solicitud.status == 1">
          <div class="col-12"><br /></div>
          <div class="row">
            <div class="col-3">
              <label> Prioridad <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <select
                v-model="solicitud.prioridad"
                :class="estatus($v.solicitud.prioridad)"
                class="form-control"
              >
                <option
                  v-for="elemento in listaPrioridades"
                  :key="elemento.id"
                  v-bind:value="elemento.id"
                >
                  {{ elemento.valor }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> Numero Oficio <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <input
                class="form-control"
                name="folioExterno"
                placeholder="Ingrese folio por favor"
                required=""
              />
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> Fecha Recepcion <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <input
                class="form-control"
                type="datetime-local"
                name="fechaRecepcion"
                required=""
              />
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label>
                Fecha de la correspondencia
                <span class="requerido">*</span></label
              >
            </div>
            <div class="col-9">
              <input
                class="form-control"
                type="datetime-local"
                name="fechaCorrespondencia"
                required=""
              />
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label>
                Fecha limite de respuesta
                <span class="requerido">*</span></label
              >
            </div>
            <div class="col-9">
              <input
                class="form-control"
                type="datetime-local"
                name="fechaLimiteRespuesta"
                required=""
              />
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> Medio <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <select
                v-model="solicitud.medio"
                :class="estatus($v.solicitud.medio)"
                class="form-control"
              >
                <option
                  v-for="elemento in listaMedios"
                  :key="elemento.id"
                  v-bind:value="elemento.id"
                >
                  {{ elemento.valor }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> Tipo <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <select
                v-model="solicitud.tipo"
                :class="estatus($v.solicitud.tipo)"
                class="form-control"
              >
                <option
                  v-for="elemento in listaTipos"
                  :key="elemento.id"
                  v-bind:value="elemento.id"
                >
                  {{ elemento.valor }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> En respuesta a <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <select
                v-model="solicitud.enrespuesta"
                :class="estatus($v.solicitud.enrespuesta)"
                class="form-control"
              >
                <option
                  v-for="elemento in listaRespuestas"
                  :key="elemento.id"
                  v-bind:value="elemento.id"
                >
                  {{ elemento.valor }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> Asunto <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <input class="form-control" name="asunto" required="" />
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-3">
              <label> Adjunto </label>
            </div>
            <div class="col-9">
              <input
                class="form-control"
                type="file"
                name="adjunto"
                accept="application/pdf"
              />
            </div>
          </div>

          <div class="col-12"><br /></div>

          <div class="row">
            <div class="col-1 col-md-1">
              <label><b>REMITENTE</b></label>
            </div>
            <div class="col-9">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label> USET/Externo <span class="requerido">*</span></label>
            </div>
            <div class="col-9">
              <select
                class="form-control"
                name="tipoEmision"
                required=""
                onchange="from(this.value, 'selectPara', 'paraSelectRec.php')"
              >
                <option value="">-Seleccione uno-</option>
                <option value="4">USET</option>
                <option value="1">Externo/Organismo</option>
              </select>
            </div>
          </div>

          <div id="selectPara"></div>

          <div id="remitente"></div>

          <div class="col-12"><br /></div>
          <div class="row">
            <div class="col-1">
              <label> <strong>DESTINATARIO</strong></label>
            </div>

            <div class="col-11">
              <hr />
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label> Para:<span class="requerido">*</span></label>
            </div>

            <div class="col-9">
              <div class="input_container col-13">
                <input
                  type="text"
                  class="form-control"
                  id="nbusuariopara"
                  onkeyup="autocompletarPara()"
                  autocomplete="off"
                  required=""
                />
                <input type="hidden" name="para" id="para" />
                <ul id="listapara_id"></ul>
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3"></div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> DETALLE <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <textarea
                  type="text"
                  name="observaciones"
                  class="form-control"
                  cols="180"
                  rows="5"
                  required=""
                ></textarea>
              </div>
            </div>

            <div class="col-12"><br /></div>
            <div class="col-lg-12" align="center">
              <input type="submit" value="Registrar" class="btn btn-primary" />
            </div>
          </div>
        </div>
        <div v-if="solicitud.status == 2">
            <div class="col-12"><br /></div>
            <div class="row">
              <div class="col-3">
                <label> Prioridad <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <select
                  v-model="solicitud.prioridad"
                  :class="estatus($v.solicitud.prioridad)"
                  class="form-control"
                >
                  <option
                    v-for="elemento in listaPrioridades"
                    :key="elemento.id"
                    v-bind:value="elemento.id"
                  >
                    {{ elemento.valor }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> Numero Oficio <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <input
                  class="form-control"
                  name="folioExterno"
                  placeholder="Ingrese folio por favor"
                  required=""
                />
              </div>
            </div>
            <div class="col-12"><br /></div>
            <div class="row">
              <div class="col-3">
                <label>
                  Fecha de la correspondencia
                  <span class="requerido">*</span></label
                >
              </div>
              <div class="col-9">
                <input
                  class="form-control"
                  type="datetime-local"
                  name="fechaCorrespondencia"
                  required=""
                />
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label>
                  Fecha limite de respuesta
                  <span class="requerido">*</span></label
                >
              </div>
              <div class="col-9">
                <input
                  class="form-control"
                  type="datetime-local"
                  name="fechaLimiteRespuesta"
                  required=""
                />
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> Medio <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <select
                  v-model="solicitud.medio"
                  :class="estatus($v.solicitud.medio)"
                  class="form-control"
                >
                  <option
                    v-for="elemento in listaMedios"
                    :key="elemento.id"
                    v-bind:value="elemento.id"
                  >
                    {{ elemento.valor }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> Tipo <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <select
                  v-model="solicitud.tipo"
                  :class="estatus($v.solicitud.tipo)"
                  class="form-control"
                >
                  <option
                    v-for="elemento in listaTipos"
                    :key="elemento.id"
                    v-bind:value="elemento.id"
                  >
                    {{ elemento.valor }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> En respuesta a <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <select
                  v-model="solicitud.enrespuesta"
                  :class="estatus($v.solicitud.enrespuesta)"
                  class="form-control"
                >
                  <option
                    v-for="elemento in listaRespuestas"
                    :key="elemento.id"
                    v-bind:value="elemento.id"
                  >
                    {{ elemento.valor }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> Asunto <span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <input class="form-control" name="asunto" required="" />
              </div>
            </div>

            <div class="col-12"><br /></div>

            <div class="row">
              <div class="col-3">
                <label> Adjunto </label>
              </div>
              <div class="col-9">
                <input
                  class="form-control"
                  type="file"
                  name="adjunto"
                  accept="application/pdf"
                />
              </div>
            </div>

            <div class="col-12"><br /></div>
            <div class="row">
              <label><b>REMITENTE</b></label>
              <div class="col-9">
                <hr />
              </div>
            </div>

            <div class="col-12"><br /></div>
            <div class="row">
              <div class="col-3">
                <label>De:<span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <select class="form-control" name="para" id="para">
                  <option value="">-Seleccione uno-</option>
                </select>
              </div>
            </div>

            <div class="col-12"><br /></div>
            <div class="row">
              <label>
                <b>DESTINATARIO</b> <span class="requerido">*</span></label
              >
              <div class="col-9">
                <hr />
              </div>
            </div>

            <div class="col-12"><br /></div>
            <div class="row">
              <div class="col-3">
                <label>USET/Externo<span class="requerido">*</span></label>
              </div>
              <div class="col-9">
                <select
                  class="form-control"
                  name="tipoEmision"
                  required=""
                  onchange="from(this.value, 'selectPara', 'paraSelectRec.php')"
                >
                  <option value="">-Seleccione uno-</option>
                  <option value="4">USET</option>
                  <option value="1">Externo/Organismo</option>
                </select>
              </div>
            </div>

            <div id="selectPara"></div>

            <div id="remitente"></div>
            <div class="col-12"><br /></div>

            <div class="col-lg-12">
              <div class="col-lg-12">
                <h3><strong>OBSERVACIONES</strong></h3>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="col-lg-12">
                <textarea
                  type="text"
                  name="observaciones"
                  class="form-control"
                  cols="180"
                  rows="5"
                ></textarea>
              </div>
            </div>
            <div class="col-12"><br /></div>
            <div class="col-lg-12" align="center">
              <input type="submit" value="Enviar" class="btn btn-primary" />
            </div>

      <div class="row">
        <span
          ><p>Campos Obligatorios (<span class="requerido">*</span>)</p></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import {api2} from "@/api";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Estatus",
  data() {
    return {
      titulo: "Agregar dependencia",
      listaPrioridades: [],
      listaGrupos: [],
      listaMedios: [],
      listaTipos: [],
      listaRespuestas: [],
      listado: [],
      buscando: false,
      solicitud: { status: 0 },
    };
  },
  validations() {
    return {
      solicitud: {
        status: {
          required,
        },
        tipo: {
          required,
        },
        prioridad: {
          required,
        },
        medio: {
          required,
        },
        enrespuesta: {
          required,
        },
        responsable: {
          //required,
        },
        enlace: {
          //required,
        },
        cargo: {
          //required,
        },
        email: {
          //required,
          email,
        },
        email2: {
          //required,
          email,
        },
        telefono: {
          //required,
          telValido,
        },
        telefonod: {
          telValido,
        },
        ext: {},
        id_usuario_responsable: {},
      },
    };
  },
  methods: {
    agregar() {
      this.titulo = "Agregar dependencia";
      this.actual = {};
      this.$refs.detalle.show();
    },
    editar(elemento) {
      (this.titulo = "Editar dependencia"),
        (this.actual = Object.assign({}, elemento));
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      if (confirm("Confirma eliminar la dependencia: " + elemento.area)) {
        api2
          .eliminarArea(elemento.id_area)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.actualizaAreas();
            } else {
              this.$parent.aviso(r.mensaje);
            }
          })
          .catch((error) => {
            this.$parent.aviso("No se pudo eliminar " + error);
          });
      }
    },
    guardar() {
      this.$v.actual.$touch();
      if (!this.$v.actual.$invalid) {
        api2
          .guardarArea(this.actual)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizaAreas();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    actualizaCatalogos() {
      api2.catalogos("prioridad").then((r) => {
        this.listaPrioridades = r.data;
      });
      api2.catalogos("catalogos", "correspondenciaGrupo").then((r) => {
        this.listaGrupos = r.data;
      });
      api2.catalogos("catalogos", "correspondenciaMedio").then((r) => {
        this.listaMedios = r.data;
      });
      api2.catalogos("catalogos", "correspondenciaTipo").then((r) => {
        this.listaTipos = r.data;
      });
      api2.catalogos("catalogos", "correspondenciaRespuesta").then((r) => {
        this.listaRespuestas = r.data;
      });
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    this.actualizaCatalogos();
  },
};
function telValido(tel) {
  if (typeof tel == "undefined") {
    return true;
  }
  if (tel == null) return true;
  if (tel.length != 10) return false;
  var re = RegExp(/^(\d{10})$/g);
  var validado = tel.match(re) != null;
  return validado;
}
</script>