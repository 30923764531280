<template>
  <iframe
    src="/codigo/correspondencia/acuerdosIndex.php"
    allow="camera *; geolocation *; microphone *; autoplay *"
    frame-id="my-ifram"
    name="my-frame"
    width="100%"
    height="700px"
    style="border: none;"
  />
  
</template>

<script>
//import VueIframe from 'vue-iframes'
export default {
  name: "Salir",
  components: {  },
  data() {
    return {
    };
  },
  methods: {
    salir() {
      this.$store
        .dispatch("terminarSesion")
        .then(() => { 
          this.$router.push('/');
          this.$parent.$forceUpdate();
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
